.home-hero { height: 100vh;
    &__slider {position: absolute; top: 0; left: 0;
               height: 100%; width: 100%;
    }
    &__swiper {height: 100%;}
    &__slide  {height: 100%;}
    &__slide-bg-container {height: 100%;
        img {width: 100%; height: 100%; object-fit: cover;}
        &:after {content:'';
            position: absolute; top: 0; left: 0;
            display: block; width: 100%; height: 100%;
            background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8253895308123249) 73%);
            mix-blend-mode: soft-light;
        }
    }
    &__content-container {position: absolute; top: 0; left: 0; z-index: 1;
                          width: 100%; height: 100%;
                          display: flex; align-items:center;
    }
    &__subtitle {font-family: "muli", sans-serif; font-size: 23px; font-weight: 400; color:white;}

}

.home-entries-map {position: relative; z-index: 1;padding-bottom: 200px;
                   background: linear-gradient(0deg, rgba(58,72,81,1) 0%, rgba(48,60,67,1) 100%);
    &:after {content:'';
             display: inline-block; width: 400px; height: 400px;
             position: absolute; bottom: 0; right: 0; z-index: 2;
             background: transparent url('../img/home-map-bg.svg') right bottom no-repeat; background-size: contain;
             opacity: .08;
    }
}

.home-entries {align-items:stretch; transform: translateY(-45%);
    &__item {display: flex; flex-wrap:wrap; justify-content:center;
             padding: 50px 30px;
             background: linear-gradient(0deg, rgba(36,45,51,.7) 0%, rgba(28,35,40,.7) 100%);
    }
    &__item-picto-container {width: 60px; height: 60px; margin-bottom: 20px;}
    &__item-picto {width: 100%; height: 100%; object-fit: contain;}
    &__item-title { text-align: center; min-height: 2.5em; width: 100%; color:white;}
    &__item-features-nav { margin-bottom: 38px; width: 100%;}
    &__item-feature {text-align: center;}
    &__item-feature-link {display: inline-block; padding: 5px 0;
                          color:white; text-decoration: none;
        &:hover {text-decoration: underline;}
    }
    &__item-btn {align-self: flex-end;}

}

.home-map {
    &__map-container {position: relative; z-index: 2;
        &:before {content: '';
            position: absolute; bottom: 0; right: 0; z-index: 1; transform: translate(30px, 30px);
            display: inline-block; width: 92%;height: 92%;      
            background: $primary;  
        }
    }
    &__map {
            height: 100%;
        
    }
    &__content { padding-right: 60px; position: relative; z-index: 3;}
    &__content-title {transform: translateX(-10%); width: 110%; color:white;}
    &__content-wysiwyg {position: relative; padding-left: 30px; margin-bottom: 60px;
                        color:white; text-align: justify;
        &:before {content:'';
                  display: inline-block; height: 2px; width: 30%;
                  position: absolute; top: 35px; right: 100%;
                  background: $primary;
        }
    }
    .btn {margin-left: 30px;}
}


.home-infos {padding: 130px 0; position: relative; overflow: hidden;
    &__bg {position: absolute; top: 50%; left: 0; transform:translateY(-50%);
            width: 100%; height: 100%;
          img { width: 100%; height: 100%; object-fit:cover;}
    }
    &__title {position: relative;
              padding-bottom: 45px; margin-bottom: 40px;
              text-align: center; color:white;
        &:after {content:'';
                 position: absolute; bottom: 0; left: 50%; transform: translateX(-50%);
                 display: inline-block; width: 80px; height: 2px;
                 background:$primary;
        }
    }
    &__content {color:white; padding: 0 40px; text-align: center; margin-bottom: 40px;}
}

@media screen and (max-width: $medium-screen) {
    .home-entries {transform: translateY(0); flex-wrap:wrap;
        &__item {width: calc((100% - 20px) / 2);margin-left:0 !important;margin-right: 20px !important; margin-bottom: 20px !important;
            @include each(2) {margin-right: 0 !important;}
        }
    }

    .home-map {
        &__map-container {aspect-ratio: 16 / 9; margin-bottom: 50px !important; margin-right: 30px !important;}
        &__content {padding-right: 0;}
        &__content-title { transform: translateX(0); width: 100%;}
        &__content-wysiwyg {padding-left: 0;
            &:before {content:none;}
        }
    }
}

@media screen and (max-width: $x-small-screen) {
    .home-hero {height: 90vh; position: relative;z-index: 200;
        &__slider {height: 90vh;}
        &__content-container {position: relative;z-index: 2; padding-right: 60px;}
    }

    .home-entries {transform: translateY(0); flex-wrap:wrap;
        &__item {width: 100%;margin-right: 0 !important;}
    }

    .home-map {
        &__map-container {aspect-ratio: 9/12; margin-right: 0 !important;
            &:before {transform: translate(15px, 15px);}
        }
        .btn {margin-left: 0;}
    }
}
.footer-before { background: $primary; padding: 55px 0;
    &__content {display:flex; align-items:center; justify-content:space-between;}
    &__title { color:white; margin-bottom: 0;}
    &__btn {margin-bottom: 0;}
}

.footer-main { background: linear-gradient(45deg, rgba(58,72,81,1) 0%, rgba(48,60,67,1) 100%); padding: 160px 0 80px;
    h3, p.title { font-size: $fz--text; color:white; }
    p {font-size: 16px;}
    &__logo {display: block; transform: translateY(-110px);
        img {max-width: 270px; height: auto;}
    }
    &__coordonnees {color:white;
        p {line-height: 22px; margin-bottom: 20px;}
    }
    &__socials-item { display: inline-block; padding-right: 20px;
        &:last-of-type {padding-right: 0;}
    }
    &__socials-link { display: inline-block; width: 30px; height: 30px;
                      border-radius:50%; overflow: hidden;
        &--lnkdn {background: $primary url('../img/lnkdn.svg') top calc(50% - 1px) left calc(50% + 1px) no-repeat; background-size: 50%;
            &:hover {background: white url('../img/lnkdn.svg') top calc(50% - 1px) left calc(50% + 1px) no-repeat; background-size: 50%;}
        }
    }
    &__legal-title { position: relative; padding-bottom: 20px;
                     color:white;
        &:after {content:'';
            position: absolute; bottom: 0; left: 0;
            display: inline-block; width: 50px; height: 2px;
            background:$primary;
        }
    }
    &__legal-menu-item {margin-bottom: 10px;}
    &__legal-menu-link {color:white; text-decoration: none;
        &:hover {text-decoration: underline;}
    }
}

.footer-after {text-align: right; padding: 15px 30px;
               background: $secondary-darkest;
    &__kagency {display: inline-block; width: 70px; height: 20px;
        img {width: 100%; height: auto;}
    }
}


@media screen and (max-width: $medium-screen) {
    .footer-before {
        &__content {flex-wrap:wrap; justify-content:center}
        &__title {width: 100%; margin-bottom: 20px; text-align: center;}
    }

    .footer-main { padding: 80px 0;
        .row {justify-content:center; text-align: center;}
        &__logo {transform: translateY(0); margin-bottom: 20px;}
        .contact__item {justify-content:center;}
        &__legal-title {
            &:after {content:none;}
        }
    }
}

/*!
Theme Name: Kagency scss
Author: Kromi
Author URI: https:/kromi.fr
Description: Kagency scss
Version: 1.0
License: GNU General Public License
License URI: https://www.gnu.org/licenses/gpl.html
Tags: 
*/


// Helpers
@import 'abstracts/family';
@import 'abstracts/mixins';
@import 'abstracts/fonts';
@import 'abstracts/variables';


// Base
@import 'base/reset';
@import 'base/helpers';
@import 'base/layout';
@import 'base/typo';
@import 'base/forms';
@import 'base/btn';

// Libs
@import 'libs/simple-grid';

// Components
@import 'components/header';
@import 'components/page-header';
@import 'components/home';
@import 'components/posts-list';
@import 'components/blocs';
@import 'components/competences';
@import 'components/faq';
@import 'components/pagination';
@import 'components/contact';
@import 'components/footer';
@import 'components/back-top';

.competence-detail {
    &__content {padding: 50px 50px 50px calc((100% - 1500px) / 2);
                background: $secondary;color:white;
        h2 { color:white;}
    }
    &__map {
        .leaflet-container {height: 100%;}
    }
}

@media screen and (max-width:$medium-screen) {
    
}
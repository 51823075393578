body {font-family: "muli", sans-serif; -webkit-font-smoothing: antialiased;font-size:$fz--text;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;}

h1,
.h1 {font-size: $fz--h1; font-family: "Cormorant Garamond", serif;
     font-weight: 700; line-height: 1.2; color:white;
     margin-bottom: 30px;}
h2,
.h2 { font-size: $fz--h2; font-family: "Cormorant Garamond", serif;
 font-weight: 700; line-height: 1.15; margin-bottom: 16px; color: $secondary}
h3,
.h3 { font-size: $fz--h3; font-family: "muli", sans-serif; font-weight: 700; line-height: 1.3; margin-bottom: 20px; color:$secondary;}


.content-wysiwyg {color:$text-color;
  p { font-size: $fz--text; line-height: 27px; font-family: "muli", sans-serif; color:inherit;
      padding-bottom: 25px;
    em { font-style: italic;}
    strong, b { font-weight: 700;}
    &:last-child { padding-bottom: 0;}

  }
  ul { margin-bottom: 20px;
    li { padding-left: 15px; position: relative; margin-bottom: 5px; color: inherit;
      strong, b { font-weight: 700;}
      &:before { content:'';
        position: absolute; top: 6px; left: 0;
        display: inline-block;
        line-height: 1;
        width: 6px; height: 6px; border-radius: 50%;
        background-color: $primary;
      }
    }
  }
  ol { margin-bottom: 20px;
    counter-reset: ol-counter;
    strong, b { font-weight: 700;}
    li {counter-increment: ol-counter; margin-bottom: 5px;
      &:before {
        content: counter(ol-counter) ". ";
        color: inherit; font-weight: bold; font-size: $fz--text--small;
      }
    }
  }
  p + ul,
  p + ol { margin-top: -10px;}
  a {color:$primary;}
}


@media screen and (max-width: $x-small-screen) {
  h1 { font-size: calc($fz--h1/1.5); word-wrap: break-word;}
  h2 { font-size: calc($fz--h2/1.5);}
  h3 { font-size: calc($fz--h3/1.3);}
}


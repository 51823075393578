.overflow-visible {overflow: visible !important;}

.clearfix:after {
    content:" ";
    display:table;
    clear:both;
}

.hide-dektop {display: none !important;}

.primary-color {color:$primary;}
.secondary-color {color:$secondary;}

.column-spacer {display: inline-block;
    &--horizontal {width: 80px; height: 2px;}
    &--primary {background: $primary;}
    &--secondary {background: $secondary;}
    &--white {background: white;}
}



@media screen and (max-width: $medium-screen) {
    .hidden-sm {display: none !important;}
  }
  
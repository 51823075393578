.faq {
    .row:first-of-type {margin-top: 30px;margin-bottom: 150px;}
    &__title { color: $secondary; margin-bottom: 0;}
    &__swiper {padding:10px 19px 40px; margin: 0 -19px 50px; }
    &__swiper-nav {position: absolute; top: 8px; right: 1px; transform:translateY(-100%);
                   display:flex; align-items: flex-end;
    }
    &__swiper-nav-link {display: inline-block; width: 60px; height: 60px;
        &--prev {margin-right: 2px;
                 background:  $secondary url('../img/arrow-left-white.svg') center no-repeat; background-size: 40%;
        }
        &--next {background:  $secondary url('../img/arrow-right-white.svg') center no-repeat; background-size: 40%;}
        &:hover {cursor:pointer; background-color:$secondary-darkest;}
    }
    &__list { display:flex; flex-wrap: wrap;}
    &__item-container { width: calc((100% - 40px)/3); margin-right: 20px; margin-bottom: 20px;
        @include each(3) {margin-right: 0;}
        .Home & {width: 100% !important; margin-right: 0 !important;}
    }
    &__item {position: relative; overflow: hidden; padding-top: 100%;
        &:hover { box-shadow: 0px 8px 15px 5px transparentize(black, .8);
            .faq__item-overlay {transform: translateY(0);}
        }
    }
    &__item-face {
                  width: 100%; height: 100%;
    }
    &__item-bg-container {
        img {position: absolute; top: 0; left: 0; z-index: 2;
             width: 100%; height: 100%;
             object-fit: cover;
             opacity: .5;
        }
    }
    &__item-bg-solid {position: absolute; top: 0; left: 0; z-index: 1;
                      width: 100%; height: 100%;
                      background: black;
                    }
    &__item-face-content {position: absolute; top: 0; left: 0; z-index: 3;
                          display:flex; flex-direction:column; justify-content:flex-end; width: 100%; height: 100%;
                          padding: 70px 50px;
                        }
    &__item-face-title {position: relative; min-height: calc(45px + 1.5em); padding-top: 45px; margin-bottom: 0;
                        font-size: 35px; font-family: "Cormorant Garamond", serif; font-weight: 700; color:white;
        &:before {content:'';
                  position: absolute; top: 0; left: 0;
                  display: inline-block; width: 80px; height: 2px;
                  background:$primary;
        }
    }
    &__item-overlay {position: absolute; top: 0;left: 0; transform: translateY(100%); z-index: 4;
                     width:100%; height: 100%;
                     background: #eaeef1;
                     transition: transform .3s linear;
    }
    &__item-overlay-content {position: absolute; top: 0; left: 0;
                             width: 100%; height: 100%; padding: 50px;
                             display:flex; flex-wrap:wrap;
    }
    &__item-overlay-title {position: relative; min-height: calc(45px + 1.5em); margin-bottom: 30px;
                           font-size: 35px; font-family: "Cormorant Garamond", serif; font-weight: 700; color:$secondary;
        &:after {content:'';
                  position: absolute; bottom: 0; left: 0;
                  display: inline-block; width: 80px; height: 2px;
                  background:$primary;
        }
    }
    &__item-overlay-desc {margin-bottom: 30px;}
    &__item-overlay-btn { align-self:flex-end;}
}


@media screen and (max-width:$medium-screen) {
    .faq {
        &__item-container { width: calc((100% - 20px)/2); margin-right: 20px;
            @include each(3) {margin-right: 20px;}
            @include each(2) {margin-right: 0;}}
    }
}

@media screen and (max-width:$small-screen) {
    .faq {
        &__item-container { width: 100%; margin-right: 0;}
        &__item-overlay-content {padding: 20px;}
        &__item-overlay-title {font-size: 28px;}
        &__item-overlay-desc {
            p {max-height: 105px; overflow: hidden;}
        }
    }
}
.page-hero {padding-top: 200px; position: relative; width: 100%; overflow: hidden;
    &__bg-container {position: absolute; top: 60px; left: 0;
                     width: 100%; height: 100%;
        img {width: 100%; height: 100%; object-fit: cover;}
        &:after {content:'';
                 position: absolute; top: 0; left: 0;
                 display: block; width: 100%; height: 100%;
                 background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8253895308123249) 73%);
                 mix-blend-mode: soft-light;
        }
    }
    &__content-container {
                          width: 100%; height: 100%;
                          display: flex; align-items:center;
        h1 {position: relative; margin-bottom: 50px;
            &:after {content:"";
                     position: absolute; bottom: 0; left: 0;
                     display: inline-block; width: 100px; height: 2px;
                     background: $primary;
            }
        }
    }
    &__breadcrumb { color:white;
        a {color:white; text-decoration: none;
            &:hover {text-decoration: underline;} 
        }
    }
}
.content-section { width: 100%; margin: 0 auto; padding: 60px 0 30px;
  ~ .content-section { padding-top: 30px;}
  &--no-pt {padding-top: 0 !important;}
  &--no-pb {padding-bottom: 0 !important;}
}

@media screen and (max-width: $x-large-screen) {
  .container {padding: 0 20px;}
}

@media screen and (max-width: $medium-screen) {
  .content-section { padding: 20px 0 20px 0 !important;}
}

@media screen and (max-width: $small-screen) {

}

@media screen and (max-width: $x-small-screen) {

}

.post-list {display:flex; flex-wrap: wrap; align-items: stretch;}

.post-item {display: flex; flex-wrap:wrap; text-align: center; justify-content:center;
            width: calc(calc(100% - 60px)/4); padding: 30px; margin-right: 20px; margin-bottom: 20px;
            text-decoration: none;
            background: $secondary;border:1px solid $secondary;
    @include each(4) {margin-right: 0;}
    &__title { font-size: $fz--h3; font-family: "muli", sans-serif; font-weight: 700; line-height: 1.3; margin-bottom: 20px; color:white;}
    &__desc {font-size: $fz--text; line-height: 27px; font-family: "muli", sans-serif; color:inherit; margin-bottom: 20px; color:white;}
    &__link {align-self: flex-end;}
    &:hover {
        .btn {background: $secondary; color:white;}
    }
}

@media screen and (max-width:$medium-screen) {
    .post-item { width: calc(calc(100% - 20px)/2);
        @include each(2) { margin-right: 0;}
    }
}

@media screen and (max-width:$small-screen) {
    .post-item { width: 100%; margin-right: 0;
        & * {width: 100%;}
        &__link {width: auto;}
    }
}
//couleurs du projet
$primary: #8e7951;
$secondary: #3a4851;
$secondary-darker: #303c43;
$secondary-darkest: #202b32;
$neutral : #688487;
$neutral-light : lighten($primary, 20%);

$light-bg: #F0F0F0;
$grey-bg : #D0D2D3;
$overlay-bg : #939393;

$border-color: #D1D3D3;

$text-color: $secondary;

$shadow: #0000001A;


// Font sizes
$fz--h1: 74px;
$fz--h2: 46px;
$fz--h3: 22px;
$fz--text: 17px;
$fz--text--small: 14px;
$fz--button: 18px;

// Border radius sizes
$br-size : 0;
$br-size--small : 0;

// Screen sizes
$x-large-screen: 1500px;
$large-screen: 1280px ;
$medium-screen: 1024px ;
$small-screen: 770px;
$x-small-screen: 500px;

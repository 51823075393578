.btn-container { margin-top: 20px;
  &--centered { text-align: center;}
  &--right {text-align: right;}
}

.btn { display: inline-block; padding: 16px 25px; margin-bottom: 10px; text-align: center; font-family: "muli", sans-serif; font-weight: 500; font-size: 16px; line-height: 1; text-decoration: none; border:2px solid;
  &:hover {cursor:pointer;}
  &--primary { background-color: $primary; color:white; border-color: $primary;
    &:hover { background-color: $secondary; color:white; }
  }
  &--secondary { background-color: $secondary; color:white; border-color: $secondary;
    &:hover { background-color: white; color:$secondary; }
  }
  &--secondary-alt { background-color: transparent; color:white; border-color: white;
    &:hover { color:$secondary; border-color:$secondary;}
  }
  &--secondary-darker { background-color: $secondary-darker; color:white; border-color: $secondary-darker;
    &:hover { background-color: transparent; color:$secondary-darker;}
  }

  &--fullwidth { width: 100%;}
  &--big { padding: 25px;}
}

@media screen and (max-width: $small-screen) {}

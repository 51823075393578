.header {position: fixed; top: 0; left: 0; z-index: 2000;
         width: 100%;
    &__top {background-color: $secondary-darkest;}
    &__top-wrapper {display:flex; justify-content: flex-end; align-items:center;
                    padding: 15px 0;
    }
    &__top-nav { position: relative;
        .nav-item {display: inline-block;
            &__link {position: relative;
                     display: inline-block; padding: 10px 20px;
                     color:white; text-decoration: none;
                &:hover {
                    &:after {content:'';
                        display: inline-block; width: calc(100% - 40px); height: 2px;
                        position: absolute; top: 100%; left: 50%; transform: translateX(-50%);
                        background: $primary;
               }
                }
            }
        }
        &:after {content:"";
                 position: absolute; right: 0; top: 50%; transform: translateY(-50%);
                 display: inline-block; width: 1px; height: calc(100% - 15px);
                 background: white; opacity: 0.3;

        }
    }
    
    &__socials-item { display: inline-block; padding: 0 20px;
        &:last-of-type {padding-right: 0;}
    }
    &__socials-link { display: inline-block; width: 30px; height: 30px;
                      border-radius:50%; overflow: hidden;
        &--lnkdn {background: white url('../img/lnkdn.svg') top calc(50% - 1px) left calc(50% + 1px) no-repeat; background-size: 50%;
            &:hover {background: $primary url('../img/lnkdn.svg') top calc(50% - 1px) left calc(50% + 1px) no-repeat; background-size: 50%;}
        }
    }
    &__main {padding: 20px 0;}
    &__logo { display: block;}
    &__main-nav:not(.mobile-menu .header__main-nav) {display: flex; justify-content:flex-end;
        .nav-item {display: inline-block; position: relative; margin-bottom: -20px;
            &__link {display: inline-block; padding:5px 20px 20px;
                color:white; text-decoration: none;
            }
            &__submenu {position: absolute; top:100%; left: 0; z-index: 2;
                                display: inline-block; width: 300px;
                                background: $primary;
                                opacity: 0;
                                pointer-events:none;
            }
            &__subitem {display: inline-block; width: 100%;}
            &__sublink {display: inline-block; width: 100%;padding: 10px 20px;
                                color:white; text-decoration: none;
                &:hover {background: $secondary;}
            }
            &:hover {
                .nav-item__submenu {opacity: 1; pointer-events:initial;}
                &:after {content:'';
                         display: inline-block; width: calc(100% - 40px); height: 2px;
                         position: absolute; top: calc(100% - 10px); left: 50%; transform: translateX(-50%);
                         background: $primary;
                }
            }
        }
       
    }

    
    &.fixed-header {background: $secondary-darker;
        .header {
            &__top-wrapper { padding: 5px 0;}
            &__main {padding: 5px 0;}
            &__logo {max-width: 280px;}
        }
    }

    &__quick-access {position: absolute; top: 120%; right: 0;}
    &__quick-access-list {position: relative; overflow: hidden;}
    &__quick-access-item {background:$primary;
                          margin-bottom: 5px;
                          transform: translateX(calc(100% - 60px));
                          transition: transform .3s linear;
        &:hover {transform: translateX(0);}
    }
    &__quick-access-link {display: flex; align-items:center; padding-right: 10px;
                          text-decoration: none;}
    &__quick-access-icon { width: 60px; height: 60px; margin-top: 1px;
        &--actes {background: transparent url('../img/actes-white.svg') center top calc(50% + 1px) no-repeat; background-size: 50%;}
        &--constat {background: transparent url('../img/constat-white.svg') center no-repeat; background-size: 50%;}
        &--paiement {background: transparent url('../img/paiement-white.svg') center no-repeat; background-size: 50%;}
    }
    &__quick-access-label { color:white; max-width: 90px; line-height: 1.2;
    }
    
}

@media screen and (max-width: $medium-screen) {
    .header {background: $secondary-darker;
        &__main {padding: 5px 0;
                 min-height: 65px;
        }
        &__logo {max-width: 280px;}
        &__top {display: none;}
        &__main-nav-wrapper {display:flex; justify-content: flex-end; padding: 0 20px;}
        &__mobile-trigger {display: inline-block; width: 30px;height: 30px;
                           background: transparent url('../img/menu-white.svg') center no-repeat; background-size: contain;
        }
        &__main-nav {}
        .mobile-menu {position: fixed; top: 65px;right: 0; transform: translateX(100%); z-index: 10;
                      width: 100%; max-width: 400px; height: calc(100% - 65px); padding: 20px 0;
                      transition: transform .3s linear;
                      background: $secondary;
                      overflow-y: auto;
            &::-webkit-scrollbar-track {
                padding: 2px 0;
                background-color: transparent;
            }
            &::-webkit-scrollbar {
                width: 10px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: $secondary-darkest;
            }
              
            &.menuopen {transform: translateX(0);}
            .nav-item {display: block;
                &__link {position: relative;
                         display: inline-block;width: 100%; padding: 10px 30px;
                         color:white; text-decoration: none;
                }
                &__navtrigger {position: absolute; right: 20px; top: 50%; transform: translateY(-50%) rotate(180deg); z-index: 10;
                               display: inline-block; width: 25px; height: 25px;
                               background: transparent url('../img/arrow-up-white.svg') center no-repeat; background-size: contain;
                               transition: transform .3s linear;
                    
                }
                &__submenu {padding-left: 20px;}
                &__sublink {display: inline-block; width: 100%; padding: 10px 30px;
                            color:white; text-decoration: none;}
                &.sub-open {
                    .nav-item__navtrigger {transform: translateY(-50%) rotate(0);}
                }
            }
        }
    }
}

@media screen and (max-width: $x-small-screen) {
    .header {
        &__main {padding: 17px 0;}
        &__logo {display: inline-block; width: 100%; height: auto;
            img { width: 100%; height: auto;}
        }
    }
}
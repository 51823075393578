.contact {
    &__item { display:inline-flex; align-items: center; width: 100%; margin-bottom: 10px;
        i {display: inline-block; width: 25px; height: 25px; margin-right: 10px;}
        a { color:white; text-decoration: none;}
        &--tel {
            i {background: transparent url('../img/tel.svg') center no-repeat; background-size: contain;}
        }
        &--mail {
            i {background: transparent url('../img/mail.svg') center no-repeat; background-size: contain;}
        }
        &--dark {
            a {color:$secondary;}
        }
    }
}
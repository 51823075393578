.pagination {display:flex; justify-content:center; align-items:center; margin: 30px auto;
             width: 100%;
    &__item { display: flex; justify-content:center; align-items:center; 
              width: 30px; height: 30px; padding: 5px;margin-right: 5px;
              border:1px solid $primary;
        @include lhCrop(1);
        &:last-or-type { margin-right: 0;}
        &:hover,
        &.active {background: $primary; color:white;}
        &.disabled {pointer-events:none;filter: grayscale(1); opacity: 0.5; }
        &--link { text-decoration: none; color:$primary;}
        &--prev {background: transparent url('../img/arrow-left-primary.svg') center no-repeat; background-size: 50%;
            &:hover {background: $primary url('../img/arrow-left-white.svg') center no-repeat; background-size: 50%;}
        }
        &--next {background: transparent url('../img/arrow-right-primary.svg') center no-repeat; background-size: 50%;
            &:hover {background: $primary url('../img/arrow-right-white.svg') center no-repeat; background-size: 50%;}
        }
    }
}
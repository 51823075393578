.bloc-bandeau {
    h2 {color:white;}
    &__band {position: relative; z-index: -1;
             background: $primary; padding: 40px 0; margin-bottom: -40px;
             text-align: center;
    }
    &__content { background: white; padding: 40px;
        h3 { color:$secondary}
    }
}

.bloc-img-txt { margin-bottom: 30px;
    h2,
    h3 {color: $secondary;}
    .image { position: relative;
        img {max-width: 100%; height: auto;position: relative; z-index: 2;}
    }
    .text { margin-bottom: 20px;}
    &--left {
        .image {order:1;
            &:before {content:"";
                position: absolute; bottom: 0; right: 0; z-index: 1; transform: translate(30px, 30px);
                display: inline-block; width: 92%;height: 92%;      
                background: $primary;  

            } 
        }
        .col-1 {order:2;}
        .content {order:3;
                  padding-right: calc((100vw - $x-large-screen) / 2)
                }
    }
    &--right {
        .image {order:3;
            &:before {content:"";
                position: absolute; bottom: 0; left: 0; z-index: 1; transform: translate(-30px, 30px);
                display: inline-block; width: 92%;height: 92%;      
                background: $primary;  

            } 
        }
        .col-1 {order:2;}
        .content {order:1;
                  padding-left:  calc((100vw - $x-large-screen) / 2)
                }
    }
}

.bloc-gallery {
    &__title {color: $secondary;}
    &__thumbnails {display:flex; flex-wrap:wrap;}
    &__item {position: relative;
             width: 20%;
             border: 1px solid $secondary-darkest;
             aspect-ratio: 1/1;
        &:after {content:'';
                 display: none; width: 100%; height: 100%;
                 position: absolute; top: 0; left: 0; z-index: 3;
        }
        &--img {
            &:after {background: transparent url('../img/magnifier-white.svg') center no-repeat; background-size: 50px 50px;}
        }
        &--video {
            &:after {background: transparent url('../img/play-white.svg') center no-repeat; background-size: 50px 50px;}
        }
        &:hover { cursor:pointer;
            .bloc-gallery__item-bg-solid {opacity: 0.5;}
            &:after {display: inline-block;}
        }
    }
    &__item-img {position: absolute; top: 0; left: 0; z-index: 2;
        width: 100%; height: 100%; object-fit:cover;
        mix-blend-mode: soft-light;
    }
    &__item-bg-solid {position: absolute; top: 0; left: 0; z-index: 1;
                      display: block; width: 100%; height: 100%;
                      background: black;
                      opacity: 0;
                      transition: opacity .3s linear;
    }
}

.lg-outer,
.lg-backdrop {z-index: 3000;}

@media screen and (max-width: $medium-screen) {
    .bloc-bandeau {
        &__band {margin-bottom: 30px;
            h2 {margin-bottom: 0;}
        }
        &__content { padding: 0 20px;}
    }

    .bloc-img-txt {
        .image,
        .content {margin-bottom: 30px;}
        &--left {
            .image {
                &:before {transform: translate(15px, 15px);}
            }
        }
        &--right {
            .image {
                &:before {transform: translate(-15px, 15px);}
            }
        }
    }
}

@media screen and (max-width: $small-screen) {
    .bloc-gallery {
        &__item {width:50%; aspect-ratio: 1/1}
    }
}
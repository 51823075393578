.form {
  &__field {position: relative; margin-bottom: 30px; width: 100%;
    label,
    legend { color:$text-color;
      a {color:$primary; text-decoration: none;
        &:hover {text-decoration: underline;}
      }
    }
    &--text,
    &--email,
    &--tel,
    &--textarea {padding: 10px 10px 0; border:1px solid $primary;
      input,
      textarea {width: 100%; padding: 8px 0;
        font-size: $fz--text; font-family: "muli", sans-serif; color: $secondary;
        border: none;
        transition: border-color 250ms;
        background-color: transparent;
        &:focus { outline: none; }

        &::placeholder { color: transparent; }

        // Hide Safari's autofill button
        &::-webkit-contacts-auto-fill-button { visibility: hidden; pointer-events: none; position: absolute; }
        &:focus + label,
        &:not(:placeholder-shown) + label {
          transform: translateY(-100%) scale(0.75);
        }
      }
      label {position: absolute; top: 16px; left: 10px;
             color: $secondary; font-family: "muli", sans-serif;
             pointer-events: none;
             transform-origin: left center; transition: transform 250ms;
      }
    }
    &--select {
      select {padding: 12px 10px; width: 100%;
              appearance:none;
              border:1px solid $primary; background: transparent url('../img/carret-down-secondary.svg') right 10px center no-repeat; background-size: 12px;
              font-size: $fz--text; font-family: "muli", sans-serif; color: $secondary;
        &:focus,
        &:active {outline:none;}
      }
    }
    &--file {height: 51px; border:1px solid $primary;
      input {position: relative; z-index: 2;
             width: 100%; height: 100%;
             margin: 0; opacity: 0;
        &:hover{cursor:pointer;}
      }
      label {position: absolute; top: 0; right: 0; left: 0; z-index: 1;
             height: 51px; padding: 10px;
             color: $secondary; line-height:1.7;
        &:after {content: attr(data-browse);
                 position: absolute; top: 0; right: 0; bottom: 0; z-index: 3;
                 display: block; height:calc(100% - 2px); padding: 10px;
                 line-height: 1.7; color: white;
                 background:$primary;
                 box-sizing: border-box;
        }
        
      } 
      &:hover {
        label:after {background:$secondary;}
      }
    }
    &--radio {line-height:16px;
      legend { margin-bottom: 10px;}
      input[type="radio"] {position: absolute; opacity: 0;
        & + Label {position: relative;
                   display: inline-block;padding-left: 20px; margin-right: 10px;
                   line-height: 1; vertical-align: middle;
          &:before,
          &:after {content:'';
                   position: absolute;
                   display: inline-block;
                   border-radius:50%;
          }
          &:before {top: 50%; left: 0; transform: translateY(-50%);
                    width: 14px; height: 14px;
                    border:1px solid $primary;
          }
          &:after {top: 50%; left: 3px; transform: translateY(-50%);
                   width: 10px; height: 10px;
                   background: $primary; opacity: 0;
          }
        }
        &:checked {
          & + label {
            &:after {opacity: 1;}
          }
        }
      }
    }
  }
  
}
